/*
 * @see https://wiki.cxense.com/display/cust/Event+data
 * Limitation: The maximum allowed identifier length is 64 characters.
 * The valid characters in customer identifiers are digits (0 to 9), 
 * characters A to Z, a to z, space 
 * and the special characters "=", "@", "+", "-", "_" and ".".
 */
const clean = str => {
  const len = 64;

  /*
  * temporary hack
  * regarding the MHNL auth0 prefixing
  */

  if (str.substr(0, 5) === 'auth0') {
    const arr = str.split('|');
    if (arr.length) {
      return arr[arr.length - 1];
    }
  }

  /*
  * end hack
  */

  str = str.replace(/[^A-Za-z0-9 =@+\-_.]/g, '');
  str = str.substring(0, len);

  return str;
};

export default clean;
